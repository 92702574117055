import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Terms = () => (
    <Layout>
        <Seo title="Terms and Conditions"/>
        <section className='section-title white'>
            <div className='container clearfix'>
                <h1>Terms and Conditions</h1>
            </div>
        </section>
        <div className='container clearfix marketplaces'>
            <h4>Epochs</h4>
            <p className='big'>
                Playground is a collection of digital artworks (NFTs) running on the Solana Network. Users are entirely
                responsible for the safety and management of their own private Solana wallets and validating all
                transactions and contracts generated by this website before approval. Furthermore, as the Playground
                smart contract runs on the Solana network, there is no ability to undo, reverse, or restore any
                transactions.
            </p>
            <p className='big'>
                This website and its connected services are provided “as is” and “as available” without warranty of any
                kind. By using this website you are accepting sole responsibility for any and all transactions involving
                Playground digital collectibles.
            </p>
            <h4>Ownership</h4>
            <p className='big'>
                i. You Own the NFT. Each Wave is an NFT on the Solana blockchain. When you purchase an NFT, you own the
                underlying Wave, the Art, completely. Ownership of the NFT is mediated entirely by the Smart Contract
                and the Solana Network: at no point may we seize, freeze, or otherwise modify the ownership of any Wave.
            </p>
            <p className='big'>
                ii. Personal Use. Subject to your continued compliance with these Terms, Playground
            </p>
            <p className='big'>
                Grants you a worldwide, royalty-free license to use, copy, and display the purchased Art, along with any
                extensions that you choose to create or use, solely for the following purposes: (i) for your own
                personal, non-commercial use; (ii) as part of a marketplace that permits the purchase and sale of your
                Wave / NFT, provided that the marketplace cryptographically verifies each Wave owner’s rights to display
                the Art for their Wave to ensure that only the actual owner can display the Art; or (iii) as part of a
                third party website or application that permits the inclusion, involvement, or participation of your
                Wave, provided that the website/application cryptographically verifies each Waves owner’s rights to
                display the Art for their Wave to ensure that only the actual owner can display the Art, and provided
                that the Art is no longer visible once the owner of the Wave leaves the website/application.
            </p>
            <p className='big'>
                iii. Commercial Use. Subject to your continued compliance with these Terms, Playground grants you an
                unlimited, worldwide license to use, copy, and display the purchased Art for the purpose of creating
                derivative works based upon the Art (“Commercial Use”). Examples of such Commercial Use would e.g. be
                the use of the Art to produce and sell merchandise products (T-Shirts etc.) displaying copies of the
                Art. For the sake of clarity, nothing in this Section will be deemed to restrict you from (i) owning or
                operating a marketplace that permits the use and sale of Waves generally, provided that the marketplace
                cryptographically verifies each Wave owner’s rights to display the Art for their Wave to ensure that
                only the actual owner can display the Art; (ii) owning or operating a third party website or application
                that permits the inclusion, involvement, or participation of Waves generally, provided that the third
                party website or application cryptographically verifies each Wave owner’s rights to display the Art for
                their Wave to ensure that only the actual owner can display the Art, and provided that the Art is no
                longer visible once the owner of the Purchased Wave leaves the website/application; or (iii) earning
                revenue from any of the foregoing.
            </p>
        </div>
    </Layout>
)

export default Terms
